<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rwpangkat_SAPK"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <rwpangkatModalToSIMASN />
      </template>
      <template v-slot:[`item.pejabat`]="{ item }">
        Nomor : {{ item.suratKeputusan.nomor }} <br />
        Tanggal : {{ item.suratKeputusan.tanggal }}
      </template>
      <template v-slot:[`item.persetujan`]="{ item }">
        Nomor : {{ item.pertekBkn.nomor }} <br />
        Tanggal : {{ item.pertekBkn.tanggal }}
      </template>
      <template v-slot:[`item.masakerjadangaji`]="{ item }">
        {{ item.masaKerjaGolongan.tahun }} Tahun -
        {{ item.masaKerjaGolongan.bulan }} Bulan <br />
        {{ item.gajiPokok }}
      </template>
      <template v-slot:[`item.jeniskenpa`]="{ item }">
        {{ item.jenisKP.nama }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="toSIMASN(item)" v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-database-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Salin Data</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiURL from "@/helper/getURL.js";
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import refreshData from "@/store/asn/rwpangkat/viewRwpangkat";
import refreshView from "@/store/asn/rwpangkat/tabs";
import modal_tosimasnState from "@/store/asn/rwpangkat/modal_to_simasn";

import rwpangkatModalToSIMASN from "@/components/asn/profil/rwpangkat/ModalToSIMASN";

export default {
  name: "rwpangkatModalSAPK",

  components: {
    rwpangkatModalToSIMASN,
  },

  computed: {
    tabSAPK: {
      get() {
        return refreshView.state.tabSAPK;
      },
    },

    refresh: {
      get() {
        return refreshData.state.RefreshSAPK;
      },
    },

    alertMassage: {
      get() {
        return refreshData.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshData.state.success;
      },

      set(value) {
        refreshData.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshData.state.alertBerhasil;
      },

      set(value) {
        refreshData.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshData.state.alertGagal;
      },

      set(value) {
        refreshData.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    tabSAPK(newVal) {
      if (newVal) {
        this.getDataSAPK();
      }
    },
    refresh() {
      this.getDataSAPK();
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    get_nip: "",
    
    Modal_rwpangkat_SAPK: false,
    loading: false,

    rwpangkat_SAPK: [],

    headers: [
      {
        text: "Golongan",
        align: "start",
        value: "golongan.nama",
        sortable: false,
      },
      { text: "TMT", value: "golongan.tmt" },
      {
        text: "Nomor dan Tanggal Surat Keputusan",
        value: "pejabat",
        width: "240px",
        sortable: false,
      },
      { text: "Persetujuan BKN", value: "persetujan" },
      { text: "Masa Kerja", value: "masakerjadangaji" },
      { text: "Jenis Kenpa", value: "jeniskenpa" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "80px",
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    if (this.tabSAPK) {
      this.getDataSAPK();
    }
  },

  methods: {
    getDataSAPK() {
      this.loading = true;
      const token = Cookie.get("token");
      const url = apiURL.baseURL + "golongan/nip/" + this.get_nip + "/bkn";

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          this.rwpangkat_SAPK = response.data.mapData.RwGolongan;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    toSIMASN(item) {
      modal_tosimasnState.commit("toggleModal", true);
      modal_tosimasnState.commit("tosimasnModal", item);
      modal_tosimasnState.commit("Datautama", this.dataUtama);
    },
  },
};
</script>
