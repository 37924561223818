import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalToSIMASN: false,
    dataUtama: {
      idASN: '',
      sapk: {
        idASN: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rwpangkat: {
      id: '',
      idASN: '',
      sapk: {
        id: '',
        idASN: ''
      },
      golongan: {
        id: '',
        tmt: ''
      },
      pertekBkn: {
        nomor: '',
        tanggal: ''
      },
      suratKeputusan: {
        pejabat: '',
        nomor: '',
        tanggal: ''
      },
      jumlahKredit: {
        utama: '',
        tambahan: ''
      },
      masaKerjaGolongan: {
        tahun: '',
        bulan: ''
      },
      jenisKP: {
        id: ''
      },
      gajiPokok: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalToSIMASN = value
    },
    tosimasnModal(state, value) {
      state.rwpangkat = value
    },
    Datautama(state, value) {
      state.dataUtama = value
    }
  }
})
