import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwpangkat: {
      id: '',
      idASN: '',
      sapk: {
        id: '',
        idASN: ''
      },
      golongan: {
        id: '',
        tmt: ''
      },
      pertekBkn: {
        nomor: '',
        tanggal: ''
      },
      suratKeputusan: {
        pejabat: '',
        nomor: '',
        tanggal: ''
      },
      jumlahKredit: {
        utama: '',
        tambahan: ''
      },
      masaKerjaGolongan: {
        tahun: '',
        bulan: ''
      },
      jenisKP: {
        id: ''
      },
      gajiPokok: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwpangkat = value
    },
  }
})
