<template>
  <div v-if="session">
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="rwpangkats"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        :loading="ldata"
        hide-default-footer
        style="text-overflow: ellipsis; white-space: nowrap"
      >
        <template v-slot:top>
          <rwpangkatModalEdit />
          <rwpangkatModalHapus />
        </template>
        <template v-slot:[`item.pejabat`]="{ item }">
          <div class="py-1">
            Pejabat : {{ item.suratKeputusan.pejabat }} <br />
            Nomor : {{ item.suratKeputusan.nomor }} <br />
            Tanggal : {{ item.suratKeputusan.tanggal }}
          </div>
        </template>
        <template v-slot:[`item.persetujan`]="{ item }">
          Nomor : {{ item.pertekBkn.nomor }} <br />
          Tanggal : {{ item.pertekBkn.tanggal }}
        </template>
        <template v-slot:[`item.masakerjadangaji`]="{ item }">
          {{ item.masaKerjaGolongan.tahun }} Tahun -
          {{ item.masaKerjaGolongan.bulan }} Bulan <br />
          {{ item.gajiPokok }}
        </template>
        <template v-slot:[`item.jeniskenpa`]="{ item }">
          {{ item.jenisKP.nama }}
        </template>
        <template
          v-slot:[`item.actions`]="{ item }"
          v-if="
            session.authorities.some((item) => item === 'CRUD_ADMINISTRATOR')
          "
        >
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwpangkatModalEdit from "@/components/asn/profil/rwpangkat/ModalEdit";
import rwpangkatModalHapus from "@/components/asn/profil/rwpangkat/ModalHapus";

import modal_editState from "@/store/asn/rwpangkat/modal_edit";
import modal_hapusState from "@/store/asn/rwpangkat/modal_hapus";
import refreshView from "@/store/asn/rwpangkat/viewRwpangkat";

export default {
  name: "rwpangkat",

  components: {
    rwpangkatModalEdit,
    rwpangkatModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },

    headers() {
      const headers = [
        {
          text: "Golongan",
          align: "start",
          value: "golongan.nama",
          sortable: false,
        },
        { text: "TMT", value: "golongan.tmt" },
        {
          text: "Pejabat yang Menetapkan, Nomor dan Tanggal Surat Keputusan",
          value: "pejabat",
          sortable: false,
        },
        { text: "Persetujuan BKN", value: "persetujan", sortable: false },
        {
          text: "Masa Kerja / Gaji Pokok",
          value: "masakerjadangaji",
          sortable: false,
        },
        { text: "Jenis Kenpa", value: "jeniskenpa" },
      ];

      if (
        this.session.authorities.some((item) => item === "CRUD_ADMINISTRATOR")
      ) {
        headers.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
        });
      }

      return headers;
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    session: "",
    get_nip: "",
    dialog: false,

    ldata: true,

    rwpangkats: [],
    rwpangkat: {},
    dataUtama: {},

    // headers: [
    //   { text: 'Golongan', align: 'start', sortable: false, value: 'golongan.nama' },
    //   { text: 'TMT', value: 'golongan.tmt', width: '95px' },
    //   { text: 'Pejabat yang Menetapkan, Nomor dan Tanggal Surat Keputusan', value: 'pejabat', width: '240px' },
    //   { text: 'Persetujuan BKN', value: 'persetujan', width: '180px' },
    //   { text: 'Masa Kerja / Gaji Pokok', value: 'masakerjadangaji' },
    //   { text: 'Jenis Kenpa', value: 'jeniskenpa' },
    // ],

    //Kebutuhan Edit dan Hapus
    editedIndex: -1,
    hapusIndex: -1,
  }),

  async mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_ASN + "golongan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ldata = false;
          refreshView.commit("refreshData", false);
          this.rwpangkats = response.data.mapData.RwGolongan;
          this.dataUtama = response.data.mapData.DataUtama;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwpangkats.indexOf(item);
      this.rwpangkat = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
      modal_editState.commit(
        "editDatautama",
        Object.assign({}, this.dataUtama)
      );
    },

    deleteItem(item) {
      this.hapusIndex = this.rwpangkats.indexOf(item);
      this.rwpangkat = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },
  },
};
</script>
